<template>
    <div class="contenedor-dashboard">
        <div class="dashboard-usuario">
            <p><b>{{info.nombre}}</b></p>
            <p>{{info.dependencia}}</p>
            <p class="cerrar-sesion"> <router-link to='/'>Cerrar Sesión</router-link></p>
        </div>
        <div class="dashboard-inicio" v-if="quienesSomos">
            <button @click="habilitarDashboard">Continuar</button>
            <div class="quienes-somos-contenedor">
                <div class="dashboard-img-left">
                    <img :src="slider.img1" alt="">
                </div>
                <div class="dashboard-texto-right">
                    <h3>¿Quiénes somos?</h3>
                    <p>Somos un conjunto de dependencias y secretarías de la Administración Pública Estatal, del Poder Legislativo, Judicial, Entidades, Organismos Autónomos y Municipios en interacción y vinculación permanente entre sí, para el desarrollo de los ejes de acción de Prevención, Atención, Sanción y Erradicación de la Violencia contra las Mujeres.</p>            
                </div>   
            </div>
            
            <div class="que-hacemos">
                <div class="que-hacemos-columna">
                    <div class="texto-columna">
                        <h3>¿Qué hacemos?</h3>
                        <p>Trabajamos por instrumentar una coordinación única para facilitar la articulación de los 4 ejes de acción (PASE), los instrumentos de la política Integral (SEPASE y el PEPASE), los servicios y la consolidación de las políticas públicas en la materia para garantizar el derecho de las mujeres, adolescentes y niñas a una vida libre de violencias en el estado.</p>
                        <p>Así como a dar cumplimiento a las obligaciones nacionales e internacionales emanadas de la Ley General de Acceso de las Mujeres y de la Convención sobre la Eliminación de Todas las Formas de Discriminación contra la Mujer (CEDAW) y la Convención Interamericana para Prevenir, Sancionar y Erradicar la Violencia contra la Mujer “Convención de Belém Do Pará”.</p>
                        <p>Desde su instalación el Sistema Estatal para Prevenir, Atender, Sancionar y Erradicar la Violencia contra las Mujeres ha sesionado de manera ordinaria en 40 ocasiones y 5 de manera Extraordinaria.</p>
                    </div>
                    <div class="dashboard-img-center">
                        <img :src="slider.nosotros" alt="">
                    </div>
                </div>
            </div>
            
            
            <div class="integrantes">
                 <h3>Integrantes</h3>
                <div class="dashboard-img-center">
                    <img :src="slider.img4" alt="">
                </div>
                <div class="integrantes-texto">
                     <p>El SEPASE atendiendo a lo establecido en el artículo 46 de la Ley de Acceso, se encuentra integrado por 14 instituciones (Poder Ejecutivo, Poder Legislativo, Poder Judicial y Organismos Constitucionales Autónomos) y 6 mediante acuerdo del pleno del SEPASE, siendo estas las siguientes:</p>
                    <ol type="I">
                        <li>SECRETARIA DE GOBIERNO;</li>
                        <li>INSTITUTO DE LA MUJER PARA EL ESTADO DE MORELOS;</li>
                        <li>FISCALÍA GENERAL DEL ESTADO DE MORELOS;</li>
                        <li>SECRETARIA DE SALUD;</li>
                        <li>COMISIÓN ESTATAL DE SEGURIDAD PÚBLICA;</li>
                        <li>INSTITUTO DE LA EDUCACIÓN BÁSICA DEL ESTADO DE MORELOS, Mediante acuerdo número: <b> PASE06/ORD36ª./31/10/2019; </b></li>
                        <li>SECRETARIA DE EDUCACIÓN;</li>
                        <li>INSTITUTO DE DESARROLLO Y FORTALECIMIENTO MUNICIPAL;</li>
                        <li>INSTITUTO MORELENSE DE PROCESOS ELECTORALES Y PARTICIPACIÓN CIUDADANA, Mediante acuerdo número: <b> PASE06/ORD40ª./14/10/2020; </b></li>
                        <li>COMISIÓN DE IGUALDAD DE GÉNERO DEL CONGRESO DEL ESTADO DE MORELOS;</li>
                        <li>SISTEMA PARA EL DESARROLLO INTEGRAL DE LA FAMILIA DIF MORELOS;</li>
                        <li>TRIBUNAL SUPERIOR DE JUSTICIA DEL ESTADO DE MORELOS, Mediante acuerdo número: <b>PASE03/ORD23ª./10/07/2015;</b></li>
                        <li>SECRETARIA DE HACIENDA;</li>
                        <li>SECRETARIA DE ADMINISTRACIÓN, Mediante acuerdo número: <b>PASE06/ORD35ª./30/07/2019;</b></li>
                        <li>COMISIÓN EJECUTIVA DE ATENCIÓN Y REPARACIÓN A VÍCTIMAS DEL ESTADO DE MORELOS;</li>
                        <li>SECRETARIA DE MOVILIDAD Y TRANSPORTE, Mediante acuerdo número: <b>PASE06/ORD36ª./31/10/2019;</b></li>
                        <li>SECRETARIA DE DESARROLLO SOCIAL;</li>
                        <li>SECRETARIA DE LA CONTRALORÍA, Mediante acuerdo número: <b>PASE06/ORD36ª./31/10/2019;</b></li>
                        <li>SECRETARIA DE DESARROLLO AGROPECUARIO;</li>
                        <li>SECRETARIA DE DESARROLLO ECONÓMICO Y DEL TRABAJO.</li>
                    </ol>
                    <h3>Invitados</h3>
                    <p>El pleno del Sistema ha invitado de manera permanente a 12 representantes de instituciones (Poder Ejecutivo, Institutos, Academia y Organismos Constitucionales Autónomos), siendo estos los siguientes:</p>
                    <ol type="I">
                        <li>SECRETARIA DE TURISMO Y CULTURA;</li>
                        <li>CONSEJERÍA JURÍDICA;</li>
                        <li>JEFE DE LA OFICINA DE LA GUBERNATURA;</li>
                        <li>COMISIÓN DE DERECHOS HUMANOS DEL ESTADO DE MORELOS;</li>
                        <li>INSTITUTO MORELENSE DE LAS PERSONAS ADOLESCENTES Y JÓVENES;</li>
                        <li>FISCALÍA ESPECIALIZADA EN EL DELITO DE FEMINICIDIO;</li>
                        <li>INSTITUTO MORELENSE DE RADIO Y TELEVISIÓN;</li>
                        <li>INEGI MORELOS;</li>
                        <li>COORDINADOR DE COMUNICACIÓN SOCIAL;</li>
                        <li>CENTRO ESTATAL DE PREVENCIÓN SOCIAL DE LA VIOLENCIA Y LA DELINCUENCIA CON PARTICIPACIÓN CIUDADANA;</li>
                        <li>SERVICIOS DE SALUD;</li>
                        <li>SISTEMA DE PROTECCIÓN INTEGRAL DE NIÑAS, NIÑOS Y ADOLESCENTES;</li>
                        <li>CRIM</li>
                    </ol>
                    <p>Así como a los 8 municipios con Declaratoria de Alerta de Violencia de Género </p>
                    <ol type="I">
                        <li>CUAUTLA;</li>
                        <li>CUERNAVACA;</li>
                        <li>EMILIANO ZAPATA;</li>
                        <li>JIUTEPEC</li>
                        <li>PUENTE DE IXTLA;</li>
                        <li>TEMIXCO;</li>
                        <li>YAUTEPEC, y </li>
                        <li>XOCHITEPEC. </li>
                    </ol>
                    <p>También se cuenta con la asistencia de 5 personas integrantes de la sociedad civil, mismos que fueron elegidos mediante convocatoria pública, siendo las siguientes:</p>
                    <ol type="I">
                        <li>CON DECISIÓN MUJERES POR MORELOS;</li>
                        <li>CREATIVERÍA SOCIAL;</li>
                        <li>CUERNAVACA AVANZA;</li>
                        <li>FUNDACIÓN MUJERES EDUCANDO EN FAMILIA, y</li>
                        <li>PSICÓLOGO JORGE ROBERTO REYES AMAYA</li>
                    </ol>
                </div>
               
            </div>
           
            
        </div>
        <div class="dashboard-principal" v-if="!quienesSomos">
            <div class="dashboard-menu">
                <div  class="dashboard-so" :class="soActive" @click="activarSO">
                    <p>Sesiones Ordinarias</p>
                </div>
                <div class="dashboard-se" :class="seActive" @click="activarSE">
                    <p>Sesiones Extraordinarias</p>
                </div>
                <div class="dashboard-acuerdo" :class="acuerdoActive" @click="activarAC"> 
                    <p>Acuerdos</p>
                </div>
            </div>
            <div class="dashboard-contenido">
                <div class="contenido-sesion-ordinaria" v-if="contenidoSO">
                    <table class="tabla-sesiones">
                        <tr>
                            <th>Tipo de Sesión</th>
                            <th>Número de Sesión</th>
                        </tr>
                        <tr>
                            <td>Sesión Ordinaria</td> 
                            <td><router-link to="/orden-dia">41</router-link></td>
                        </tr>
                        <tr>
                            <td>Sesión Ordinaria</td>
                            <td><router-link to="/orden-dia-42">42</router-link></td>
                        </tr>
                        <tr>
                            <td>Sesión Ordinaria</td>
                            <td><router-link to="/orden-dia-43">43</router-link></td>
                        </tr>
                    </table>
                </div>
                <div class="contenido-sesion-extraordinaria" v-if="contenidoSE">
                    <p>Sesion Extraordinarias</p>
                </div>
                <div class="contenido-acuerdos" v-if="contenidoAC">
                    <iframe :src="pdfAcuerdos" frameborder="0" width="90%" height="800px;"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from "vuex"

export default {
    data() {
        return {
            quienesSomos:true,
            soActive: 'activo',
            seActive: '',
            acuerdosActive: '',
            contenidoSO: true,
            contenidoSE: false,
            contenidoAC: false,
            info:{
                nombre: '',
                dependencia: ''
            },
            pdfAcuerdos:'../pdf/seguimiento_acuerdos.pdf',
            slider:{
                img1: '../img/logo_sepase.png',
                img2: '../img/imagen2.png',
                img3: '../img/imagen3.png',
                img4: '../img/integrantes-sepase.png',
                nosotros: '../img/img-nosotros.png'
            }
        }
    },
    created(){
        this.leerDatos()
    },
    methods:{
        habilitarDashboard(){
            this.quienesSomos = false 
        },
        activarSO(){
            this.soActive = 'activo'
            this.seActive = ''
            this.acuerdoActive = ''
            this.contenidoSO = true
            this.contenidoSE = false
            this.contenidoAC = false
        },
        activarSE(){
            this.soActive = ''
            this.seActive = 'activo'
            this.acuerdoActive = ''
            this.contenidoSO = false
            this.contenidoSE = true
            this.contenidoAC = false
        },
        activarAC(){
            this.soActive = ''
            this.seActive = ''
            this.acuerdoActive = 'activo'
            this.contenidoSO = false
            this.contenidoSE = false
            this.contenidoAC = true
        },
        leerDatos(){
            this.info.nombre = this.nombre
            this.info.dependencia = this.dependencia
        }
    },
    computed: {
        ...mapState(['nombre','dependencia'])
    }
}
</script>